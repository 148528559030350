<template>
  <a-modal
    v-model="addSourceVisible"
    :title="(isAdd ? '添加' : '修改') + ''"
    @onCancel="handleCancel"
    @ok="handleSubmit"
  >
    <a-form-model ref="ruleForm" :model="query" :rules="rules" v-bind="layout"> </a-form-model>
  </a-modal>
</template>

<script>
export default {
  data () {
    return {
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 14 }
      },
      query: {},
      rules: {}
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    isAdd: {
      type: Boolean,
      default: true
    },
    sourceInfo: {
      default: () => ({}),
      type: Object
    }
  },
  watch: {
    sourceInfo: {
      handler (val) {},
      deep: true,
      immediate: true
    }
  },
  computed: {
    addSourceVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    }
  },
  methods: {
    handleCancel () {
      this.addSourceVisible = false
    },
    handleSubmit () {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          if (this.isAdd) {
            // const resp = await appAdd(this.query)
            // if (resp.code === 200) {
            //   this.$message.success('添加成功!')
            //   this.$emit('changeAppInfo', 'add')
            // }
          } else {
            // const resp = await appUpdate(this.query)
            // if (resp.code === 200) {
            //   this.$message.success('修改成功!')
            //   this.$emit('changeAppInfo', 'update')
            // }
          }
          this.addSourceVisible = false
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style></style>
